import React, { useEffect } from "react";
import { Form, Radio } from "antd";

const ThirdHealthForm = ({ form, firstName, setFormDone }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="health-form">
      <div className="health-form__third">
        <section className="health-form__info">
          <h1 className="health-form__title mb-5 has-text-weight-bold line-height-2 has-text-centered">
            Lovely to meet you {firstName}! What’s your total household income?
          </h1>
        </section>

        <section className="health-form__third__forms">
          <Form form={form} layout="vertical" size="large" hideRequiredMark>
            <Form.Item name="household_income">
              <Radio.Group
                onChange={(e) => setFormDone(true)}
                className="health-form__third__forms__radios"
              >
                <Radio value="45k-above">$45K AND ABOVE</Radio>
                <Radio value="30k-45k">$30K TO $45K</Radio>
                <Radio value="16k-30k">$16K TO $30K</Radio>
                <Radio value="16k-less">$16K AND LESS</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </section>
      </div>
    </div>
  );
};

export default ThirdHealthForm;
