import React, { useEffect } from "react";
import { Button } from "antd";
import { PhoneOutlined } from "@ant-design/icons";

const SERVICE_LINE = process.env.INSURITA_SERVICE_LINE;

const SuccessHealthForm = ({ form, firstName }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="health-form">
      <div className="health-form__second">
        <section className="health-form__info">
          <h1 className="health-form__title mb-4 has-text-weight-bold line-height-2 has-text-centered">
            Congratulations {firstName},
            <br />
            We have your request!
          </h1>
          <p className="mb-5 line-height-3 has-text-weight-light has-text-centered">
            We will get back to you as soon as we can! But if you want to immediately talk to us
            about your health plan, you can call is directly.
          </p>
        </section>

        <section className="health-form__forms">
          <Button
            className="button is-primary is-outlined"
            type="primary"
            size="large"
            block
            onClick={() => window.open("tel:(833)649-0448", "_self")}
          >
            <PhoneOutlined />
            CALL {SERVICE_LINE}
          </Button>
        </section>
      </div>
    </div>
  );
};

export default SuccessHealthForm;
