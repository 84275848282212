import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Progress, Avatar } from "antd";
import { motion } from "framer-motion";
import { navigate } from "gatsby";
import Layout from "../../components/Layout";
import agentImage from "../../img/insuremartusa-agent.jpg";

import FirstHealthForm from "../../components/forms/FirstHealthForm";
import SecondHealthForm from "../../components/forms/SecondHealthForm";
import ThirdHealthForm from "../../components/forms/ThirdHealthForm";
import FourthHealthForm from "../../components/forms/FourthHealthForm";
import FifthHealthForm from "../../components/forms/FifthHealthForm";
import SuccessHealthForm from "../../components/forms/SuccessHealthForm";

import client from "../../helpers/apiConfig";
import * as analytics from "../../helpers/analytics";
import fbTrack from "../../helpers/pixel";

import "./styles/your-health-plan.scss";

const YourHealthPlanPage = ({ location }) => {
  const { state, pathname = "" } = location;
  const [form] = Form.useForm();
  const [stepState, setStepState] = useState(0);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [formDone, setFormDone] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [selectedPlanType, setSelectedPlanType] = useState("");
  const buttonRef = useRef(null);

  useEffect(() => {
    if (state) {
      form.setFieldsValue({ zip: state.zip });
    }
  }, [state, form]);

  const onSaveForm = () => {
    if (stepState === 5) {
      navigate("/");
    } else {
      form
        .validateFields()
        .then(async (values) => {
          setFormData((prevState) => ({
            ...prevState,
            ...values
          }));

          if (stepState === 0) {
            setFormData((prevState) => ({
              ...prevState,
              plan_type: selectedPlanType
            }));
            setStepState(1);
          } else if (stepState === 1) {
            setFirstName(values.first_name);
            setStepState(2);
          } else if (stepState === 4) {
            setLoading(true);
          } else {
            setStepState(stepState + 1);
            setFormDone(false);
          }
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    }
  };

  useEffect(() => {
    const userData = {};

    userData.first_name = formData.first_name;
    userData.last_name = formData.last_name;
    userData.dob = `${formData.month}/${formData.day}/${formData.year}`;
    userData.phone_number = formData.phone_number;
    userData.email = formData.email;
    userData.household_income = formData.household_income;
    userData.plan_type = formData.plan_type;

    const sendLead = async () => {
      try {
        const response = await client.post("/post_lead?vendor_id=99", userData, {
          headers: {
            "Content-Type": "application/json"
          }
        });

        if (response) {
          setStepState(5);
          setFormDone(false);
        }

        setLoading(false);
        analytics.submitRequestForm("success");
        fbTrack("track", "Lead Form Success", userData);
      } catch (error) {
        setLoading(false);

        analytics.submitRequestForm("failed");

        if (error.response) {
          form.setFields([
            {
              name: "phone_number",
              errors: [error.response.data]
            }
          ]);
        }
      }
    };

    if (loading) {
      sendLead();
    }
  }, [loading]);

  const renderForm = () => {
    const transition = { duration: 0.35, ease: [0.43, 0.13, 0.23, 0.96] };

    switch (stepState) {
      case 0:
        return (
          <motion.div
            key={0}
            initial={{ opacity: 0, x: 200, transition }}
            animate={{ opacity: 1, x: 0, transition }}
          >
            <FirstHealthForm
              form={form}
              setFormDone={setFormDone}
              setSelectedPlanType={setSelectedPlanType}
              selectedPlanType={selectedPlanType}
            />
          </motion.div>
        );
      case 1:
        return (
          <motion.div
            key={1}
            initial={{ opacity: 0, x: 200, transition }}
            animate={{ opacity: 1, x: 0, transition }}
          >
            <SecondHealthForm
              form={form}
              selectedPlanType={selectedPlanType}
              setFormDone={setFormDone}
            />
          </motion.div>
        );

      case 2:
        return (
          <motion.div
            key={1}
            initial={{ opacity: 0, x: 200, transition }}
            animate={{ opacity: 1, x: 0, transition }}
          >
            <ThirdHealthForm form={form} firstName={firstName} setFormDone={setFormDone} />
          </motion.div>
        );
      case 3:
        return (
          <motion.div
            key={1}
            initial={{ opacity: 0, x: 200, transition }}
            animate={{ opacity: 1, x: 0, transition }}
          >
            <FourthHealthForm form={form} buttonRef={buttonRef} setFormDone={setFormDone} />
          </motion.div>
        );
      case 4:
        return (
          <motion.div
            key={1}
            initial={{ opacity: 0, x: 200, transition }}
            animate={{ opacity: 1, x: 0, transition }}
          >
            <FifthHealthForm form={form} setFormDone={setFormDone} />
          </motion.div>
        );
      case 5:
        return (
          <motion.div
            key={1}
            initial={{ opacity: 0, x: 200, transition }}
            animate={{ opacity: 1, x: 0, transition }}
          >
            <SuccessHealthForm firstName={firstName} />
          </motion.div>
        );

      default:
        return null;
    }
  };

  const getProgress = () => {
    switch (stepState) {
      case 0:
        return 42;
      case 1:
        return;
      case 2:
        return 58;
      case 3:
        return 72;
      case 4:
        return 89;
      case 5:
        return 100;

      default:
        return 0;
    }
  };

  return (
    <Layout pathname={pathname}>
      <div className="health-plan wrapper-sm">
        <div className="container">
          {stepState !== 0 && (
            <section className="health-plan__agent">
              <div className="health-plan__agent__image">
                <Avatar size={80} src={agentImage} />
              </div>
              <Progress percent={getProgress()} />
            </section>
          )}

          <section className="health-plan__forms">{renderForm()}</section>

          <section className="health-plan__actions">
            <Button
              ref={buttonRef}
              onClick={onSaveForm}
              type="primary"
              size="large"
              block
              disabled={loading || (!formDone && stepState !== 5)}
              loading={loading}
            >
              {stepState === 4 ? "FIND PLAN" : stepState === 5 ? "BACK TO HOME" : "NEXT"}
            </Button>
          </section>

          {stepState === 4 && (
            <section className="health-plan__policy">
              <p>
                By clicking “FIND PLAN” I provide my electronic signature and express written
                consent to telemarketing calls, text messages, emails, and postal mail from this Web
                site, our marketing and re-marketing network, and up to eight insurance companies or
                their affiliates at the phone number (including wireless number), email address, and
                postal address provided by me. I consent to calls and text messages transmitting
                insurance quotes, or seeking related additional information from me, using an
                Automatic Telephone Dialing System or prerecorded or artificial voices. I understand
                that my signature is not a condition of purchasing any property, goods, or services
                and that I may revoke my consent at any time. Additionally, by clicking “Get My
                Health Quotes,” I acknowledge that I have read, understand, and agree to this Web
                site’s Privacy Policy.
              </p>
            </section>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default YourHealthPlanPage;
