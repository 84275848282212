import React, { useEffect, useState, useRef } from "react";
import { Form, Input } from "antd";

const FourthHealthForm = ({ form, buttonRef, setFormDone }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dayRef = useRef(null);
  const yearRef = useRef(null);
  const monthRef = useRef(null);
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [year, setYear] = useState("");

  useEffect(() => {
    if (month && day && year) {
      setFormDone(true);
    } else {
      setFormDone(false);
    }
  }, [month, day, year]);

  return (
    <div className="health-form">
      <div className="health-form__third">
        <section className="health-form__info">
          <h1 className="health-form__title mb-5 has-text-weight-bold line-height-2 has-text-centered">
            When’s your birthday?
          </h1>
        </section>

        <section className="health-form__fourth__forms">
          <Form form={form} layout="vertical" size="large" hideRequiredMark>
            <div className="health-form__fourth__forms__birthday">
              <div className="health-form__fourth__forms__birthday__label">
                <span>BIRTHDATE</span>
              </div>
              <Form.Item name="month">
                <Input
                  ref={monthRef}
                  placeholder="MM"
                  style={{ width: "100%", borderRadius: 0 }}
                  type="number"
                  pattern="\d*"
                  onChange={(e) => {
                    setMonth(e.target.value);
                    if (e.target.value.length === 2) {
                      dayRef.current.focus();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item name="day">
                <Input
                  ref={dayRef}
                  placeholder="DD"
                  style={{ width: "100%", borderRadius: 0 }}
                  type="number"
                  pattern="\d*"
                  onChange={(e) => {
                    setDay(e.target.value);
                    if (e.target.value.length === 2) {
                      yearRef.current.focus();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item name="year">
                <Input
                  ref={yearRef}
                  placeholder="YYYY"
                  style={{ width: "100%", borderRadius: "0 4px 4px 0" }}
                  type="number"
                  pattern="\d*"
                  onChange={(e) => {
                    setYear(e.target.value);
                    if (e.target.value.length === 4) {
                      buttonRef.current.focus();
                    }
                  }}
                />
              </Form.Item>
            </div>
          </Form>
        </section>
      </div>
    </div>
  );
};

export default FourthHealthForm;
