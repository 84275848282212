import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { AsYouType, parsePhoneNumberFromString } from "libphonenumber-js";

const FifthHealthForm = ({ form, selectedPlanType, setFormDone }) => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const formatedNumber = parsePhoneNumberFromString(phone, "US");
    if (formatedNumber) {
      if (email && formatedNumber.isValid()) {
        setFormDone(true);
      } else {
        setFormDone(false);
      }
    }

    // if (email && phone) {
    //   setFormDone(true);
    // } else {
    //   setFormDone(false);
    // }
  }, [email, phone]);

  return (
    <div className="health-form">
      <div className="health-form__second">
        <section className="health-form__info">
          <h1 className="health-form__title mb-5 has-text-weight-bold line-height-2 has-text-centered">
            That was quick! Let’s find that individual plan!
          </h1>
        </section>

        <section className="health-form__forms">
          <Form form={form} layout="vertical" size="large" hideRequiredMark>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required"
                },
                { type: "email", message: "Enter a valid email address" }
              ]}
            >
              <Input
                placeholder="EMAIL ADDRESS"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
              />
            </Form.Item>
            <Form.Item
              getValueFromEvent={(e) => {
                const value = e.target.value;

                if (value.includes("(") && !value.includes(")")) {
                  return value.replace("(", "");
                }
                return new AsYouType("US").input(value);
              }}
              name="phone_number"
              rules={[
                {
                  required: true,
                  message: "Phone is required"
                }
              ]}
            >
              <Input
                placeholder="PHONE NUMBER"
                type="tel"
                pattern="\d*"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
            </Form.Item>
          </Form>
        </section>
      </div>
    </div>
  );
};

export default FifthHealthForm;
