import React, { useEffect } from "react";
import individualDarkImage from "../../img/individual-mart-dark.png";
import individualImage from "../../img/individual-mart.png";
import familyDarkImage from "../../img/family-mart-dark.png";
import familyImage from "../../img/family-mart.png";

import "./styles/health-form.scss";

const FirstHealthForm = ({ form, setFormDone, setSelectedPlanType, selectedPlanType }) => {
  useEffect(() => {
    if (selectedPlanType) {
      setFormDone(true);
    }
  }, [selectedPlanType]);

  return (
    <div className="health-form has-text-centered">
      <div className="health-form__first">
        <h1 className="health-form__title mb-5 has-text-weight-bold line-height-2">
          Are you looking for an individual or a family plan?
        </h1>

        <section className="health-form__first__cards">
          <div
            onClick={() => setSelectedPlanType("Individual")}
            className={`health-form__first__cards__card-individual${
              selectedPlanType === "Individual"
                ? " health-form__first__cards__card-individual--active"
                : ""
            }`}
          >
            <span
              className={`health-form__first__cards__card-individual__text${
                selectedPlanType === "Individual"
                  ? " health-form__first__cards__card-individual__text--active"
                  : ""
              }`}
            >
              INDIVIDUAL
            </span>
          </div>
          <div
            onClick={() => setSelectedPlanType("Family")}
            className={`health-form__first__cards__card-family${
              selectedPlanType === "Family" ? " health-form__first__cards__card-family--active" : ""
            }`}
          >
            <span
              className={`health-form__first__cards__card-family__text${
                selectedPlanType === "Family"
                  ? " health-form__first__cards__card-familys__text--active"
                  : ""
              }`}
            >
              FAMILY
            </span>
          </div>
        </section>
      </div>
    </div>
  );
};

export default FirstHealthForm;
